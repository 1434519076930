import './services.scss'
import {getPropsFromState} from "../../redux/mapStateToProps";
import {connect} from "react-redux";
import {GetServices} from "../../redux/actions";
import {useEffect, useRef} from "react";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {ArrowLinkIcon, bg_decorative} from "../../assets/images";

import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import {CountUp} from "use-count-up";
import Slider from "react-slick";
import {Link} from "react-router-dom";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";

function Services(props) {
    const {services, partners, staticTexts} = props

    let sliderRef = useRef(null);
    const settings = {
        className: "slider variable-width",
        dots: false,
        arrows: false,
        autoplay:true,
        infinite:true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        ref: partner => (sliderRef = partner),
    };

    useEffect(() => {
        props.GetServices()
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])

    return <div className={'service-wrapper'}>
        <img src={bg_decorative} className={'service-wrapper-bg'} alt=""/>
        <div className="top-part">
            <div className={'service-sub-title'}>{staticTexts?.service_sub_title}</div>
            <div className={'service-title'}>{services?.title}</div>
            <div className={'service-description'}>{services?.description}</div>

            {!!services?.blocks?.[0] && <div className="block-first">
                {
                    !!services?.blocks?.[0]?.options?.length
                    && services?.blocks?.[0]?.options?.map(item => {
                        return <div className="option-item" key={item?.id}>
                            <div className="option-item-img">
                                <img src={generateImageMediaUrl(item?.mediaMain?.path)}
                                     title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                     alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                            </div>

                            <div className="option-item-title">{item?.title}</div>
                        </div>
                    })
                }
            </div>}

            {!!services?.blocks?.[1] && <div className="block-second">
                <div className="left-part">
                    <div className="block-title">{services?.blocks?.[1]?.title}</div>
                    <div className="block-description">{services?.blocks?.[1]?.description}</div>
                    <Link to={getUrlWithLocal('/contact')} className="block-btn">{staticTexts?.btn_view_us} <ArrowLinkIcon/></Link>
                </div>
                <div className="right-part">
                    <div className="bg-wrapper">
                        <div className="bg"/>
                    </div>
                    <div className="img-wrapper">
                        <img src={generateImageMediaUrl(services?.blocks?.[1]?.mediaMain?.path)}
                             className="option-item-img"
                             title={services?.blocks?.[1]?.mediaMain?.title ? services?.blocks?.[1]?.mediaMain?.title : ''}
                             alt={services?.blocks?.[1]?.mediaMain?.altAttribute ? services?.blocks?.[1]?.mediaMain?.altAttribute : ''}/>
                    </div>

                    <VisibilitySensor>{({isVisible}) => {
                        return (
                            <div className="count-item-first">
                                <div className={'item-title'}>
                                    <CountUp isCounting={isVisible} start={0}
                                             end={Number(services?.blocks?.[1]?.options[0]?.title)}
                                             duration={1} delay={4} easing="linear"/>
                                    +
                                </div>
                                <div
                                    className={'item-description'}>{services?.blocks?.[1]?.options[0]?.description}</div>
                            </div>
                        );
                    }}</VisibilitySensor>

                    <VisibilitySensor>{({isVisible}) => {
                        return (
                            <div className="count-item-second">
                                <div className={'item-title'}>
                                    <CountUp isCounting={isVisible} start={0}
                                             end={Number(services?.blocks?.[1]?.options[1]?.title)}
                                             duration={1} delay={4} easing="linear"/>
                                    +
                                </div>
                                <div
                                    className={'item-description'}>{services?.blocks?.[1]?.options[1]?.description}</div>
                            </div>
                        );
                    }}</VisibilitySensor>
                </div>
            </div>}

            {services?.blocks?.[2] && <div className={'blocks-third'}>
                <div className="blocks-third-title">{services?.blocks?.[2]?.title}</div>
                <div className="blocks-third-description">{services?.blocks?.[2]?.description}</div>
                <div className="options-list">
                    {
                        services?.blocks?.[2]?.options?.slice(0, 4)?.map(item => {
                            return <div className="option-item" key={item?.id}>
                                <div className="option-item-img">
                                    <img src={generateImageMediaUrl(item?.mediaMain?.path)}
                                         title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                         alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                                </div>

                                <div className="option-item-title">{item?.title}</div>
                                <div className="option-item-description">{item?.description}</div>
                            </div>
                        })
                    }
                </div>
            </div>}

            {!!services?.blocks?.[3] && <div className="block-fourth">
                <div className="left-part">
                    <div className="bg-wrapper">
                        <div className="bg"/>
                    </div>
                    <div className="img-wrapper">
                        <img src={generateImageMediaUrl(services?.blocks?.[3]?.mediaMain?.path)}
                             className="option-item-img"
                             title={services?.blocks?.[3]?.mediaMain?.title ? services?.blocks?.[3]?.mediaMain?.title : ''}
                             alt={services?.blocks?.[3]?.mediaMain?.altAttribute ? services?.blocks?.[3]?.mediaMain?.altAttribute : ''}/>
                    </div>

                    <VisibilitySensor>{({isVisible}) => {
                        return <div className="count-item-first">
                            <div className={'item-title'}>
                                <CountUp isCounting={isVisible} start={0}
                                         end={Number(services?.blocks?.[3]?.options[0]?.title)}
                                         duration={1} delay={4} easing="linear"/>
                                +
                            </div>
                            <div className={'item-description'}>
                                {services?.blocks?.[3]?.options[0]?.description}
                            </div>
                        </div>
                    }}</VisibilitySensor>

                    <VisibilitySensor>{({isVisible}) => {
                        return (
                            <div className="count-item-second">
                                <div className={'item-title'}>
                                    <CountUp isCounting={isVisible} start={0}
                                             end={Number(services?.blocks?.[3]?.options[1]?.title)}
                                             duration={1} delay={4} easing="linear"/>
                                    +
                                </div>
                                <div
                                    className={'item-description'}>{services?.blocks?.[3]?.options[1]?.description}</div>
                            </div>
                        );
                    }}</VisibilitySensor>
                </div>
                <div className="right-part">
                    <div className="block-title">{services?.blocks?.[3]?.title}</div>
                    <div className="block-description">{services?.blocks?.[3]?.description}</div>
                    <Link to={getUrlWithLocal('/contact')} className="block-btn">{staticTexts?.btn_view_us} <ArrowLinkIcon/></Link>
                </div>
            </div>}
        </div>
        <div className="bottom-part">
            <p className="bottom-part-text">
                {staticTexts?.service_partners_description}
            </p>
            {!!partners?.length && <div className="partners-list-wrapper">
                <Slider {...settings}>
                    {partners?.map(item => {
                        return <div key={item}>
                            <div className="partner-item" key={item.id}>
                                <div className="partner-content">
                                    <img src={generateImageMediaUrl(item?.mediaMain?.path)}
                                         className="fade-image"
                                         title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                         alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                                </div>
                            </div>
                        </div>
                    })}
                </Slider>
            </div>}
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'requestLoading',
        'staticTexts',
        'services',
        'partners',
    ])
};

const mapDispatchToProps = {
    GetServices
};


export default connect(mapStateToProps, mapDispatchToProps)(Services);
