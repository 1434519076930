import './HomeContact.scss'
import {DownloadIcon} from "../../../assets/images";
import {Link} from "react-router-dom";
import {generateFileMediaUrl} from "../../../utils/generateMediaUrl";
import {getUrlWithLocal} from "../../../utils/getUrlWithLocal";

function HomeContact(props) {
    const {block, staticTexts} = props

    function downloadPDF() {
        const pdfURL = generateFileMediaUrl(block?.pdfs?.[0]?.path);
        const downloadLink = document.getElementById('downloadLink');
        downloadLink.setAttribute('download', 'example.pdf');
        downloadLink.setAttribute('href', pdfURL);
        downloadLink.click();
    }

    return <div className={'home-contact'}>
        <div className="home-contact-left">
            <div className="home-contact-sub-title">{staticTexts?.homepage_contact_title}</div>
            <div className="home-contact-title">{block?.title}</div>
            <div className="home-contact-description">
                {block?.description}
            </div>
            <div className="home-contact-link" onClick={downloadPDF}>
                <span><DownloadIcon/>{staticTexts?.homepage_contact_btn}</span>
            </div>
            <Link to={getUrlWithLocal('/contact')} className="home-contact-button">Միացման հայտ</Link>
        </div>
        <a id="downloadLink" style={{display: 'none'}}></a>
        <div className="home-contact-right">
            <div className="bg"/>
        </div>
    </div>
}

export default HomeContact
