// Import packages
import React from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import Layout from "../containers/Layout";

// Import utils
import {history} from "../configs/history";

// Import pages
import Homepage from "../containers/homepage/Homepage";
import Services from "../containers/service/Services";
import About from "../containers/about/About";
import Info from "../containers/info/Info";
import Contact from "../containers/contact/Contact";
import Product from "../containers/product/Product";
import {store} from "../redux/store";
import News from "../containers/news/News";


const locales = ['hy', 'en', 'ru']

export default function RoutesBlocks() {
    const location = useLocation()
    // const {location} = this.props
    const path = location?.pathname
    // const action = props?.history?.action
    const {selectedLanguage, languages} = store.getState().general || {}
    const selectedCode = selectedLanguage?.code || languages?.find(lg => lg.isMain)?.code || 'hy'
    const actualLocals = (languages && languages.length && languages.map(lg => lg.code)) || locales
    if (!location || path === '/') {
        history.push(`/${selectedCode}`)
        return null
    }
    let locale = path.split('/')[1]

    if (!actualLocals.includes(locale)) {
        const newPath = `/${selectedCode}${path}`
        history.push(newPath)
        return null
    }
    return <Layout>
        <Routes>
            <Route path={`/${locale}`} element={<Homepage/>}/>
            <Route path={`/${locale}/services`} element={<Services/>}/>
            <Route path={`/${locale}/about`} element={<About/>}/>
            <Route path={`/${locale}/info`} element={<Info/>}/>
            <Route path={`/${locale}/contact`} element={<Contact/>}/>
            <Route path={`/${locale}/products`} element={<Product/>}/>
            <Route path={`/${locale}/news`} element={<News/>}/>
            {/*<Route path="/fitness/:id" element={<FitnessSection/>}/>*/}
            {/*<Route path="/spa" element={<Spa/>}/>*/}
            {/*<Route path="/cafe" element={<Cafe/>}/>*/}
            {/*<Route path="/event/detail/:id" element={<EventDetail/>}/>*/}
            {/*<Route path="/article/detail/:id" element={<ArticleDetail/>}/>*/}
            {/*<Route path="/pool" element={<Pool/>}/>*/}
            {/*<Route path="/404" element={<NotFound/>}/>*/}
            {/*<Route path="*" element={ <Navigate to="/404" replace />} />*/}
        </Routes>
    </Layout>
}
