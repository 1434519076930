// ----------------------------------4Steps-server---------------------------------------------------
// const ADMIN_URL = "http://173.212.233.220:3411/api/v1";
// const BASE_URL =  "http://173.212.233.220:3412/api/v1";
// export const HOST_MEDIA_URL_V1 = "http://173.212.233.220:3413";


// //----------------------------------Official-server---------------------------------------------------
const ADMIN_URL = "https://app.hs.am/admin/api/v1";
const BASE_URL ="https://app.hs.am/front/api/v1";
export const HOST_MEDIA_URL_V1 = "https://app.hs.am/file";



export const _urlLanguage = BASE_URL + "/language";
export const _urlStaticTexts = BASE_URL + "/static-texts";
export const _urlPartners = BASE_URL + "/partner";
export const _urlInfo = BASE_URL + "/info";
export const _urlProducts = BASE_URL + "/products";
export const _urlContacts = BASE_URL + "/contact";
export const _urlCall = BASE_URL + "/call-request";
export const _urlOrder = BASE_URL + "/order-request";
export const _urlMenu = BASE_URL + "/menu";
export const _urlSubscribers = BASE_URL + "/subscribers";
export const _urlServices = BASE_URL + "/service";
export const _urlSponsors = BASE_URL + "/sponsors";
export const _urlNews = BASE_URL + "/news";
export const _urlHome = BASE_URL + "/home";
export const _urlAbout = BASE_URL + "/about";
export const _urlCountryCode1 = "http://ip-api.com/json";

