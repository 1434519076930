import './HomePackage.scss'
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import React from "react";

function HomePackage(props) {
    const {block} = props;

    return <div className={'home-package'}>
        <div className={'home-package-title'}>
            {block?.title}
        </div>
        <div className={'home-package-description'}>
            {block?.description}
        </div>
        <div className={'home-package-blocks'}>
            {
                block?.options?.length && block?.options?.map(item => {
                    return <div className={'package-card'}>
                        <div className={'package-card-icon'}>
                            <img src={generateImageMediaUrl(item?.mediaMain?.path)} className="fade-image"
                                 title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                 alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>

                        </div>
                        <div className={'package-card-title'}>
                            {item?.title}
                        </div>
                        <div className={'package-card-description'}>
                            {item?.description}
                        </div>
                    </div>
                })
            }
        </div>
    </div>

}

export default HomePackage
