import {useEffect} from "react";
import {connect} from "react-redux";

import './Homepage.scss'

import {getPropsFromState} from "../../redux/mapStateToProps";

import MainBlock from "../../components/homepage/mainBlock/MainBlock";
import HomePartners from "../../components/homepage/partners/HomePartners";
import HomeBlock from "../../components/homepage/homeBlock/HomeBlock";
import HomeServices from "../../components/homepage/homeServices/HomeServices";
import HomeContact from "../../components/homepage/homeContact/HomeContact";
import HomePackage from "../../components/homepage/homePackage/HomePackage";
import Contracts from "../../components/homepage/contracts/Contracts";
import MapBlock from "../../components/homepage/map/MapBlock";
import Subscribers from "../../components/homepage/Subscribers/Subscribers";

function Homepage(props) {
    const {partners, staticTexts, home, contacts, requestLoading, services} = props;
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])

    return <div className={'homepage-wrapper'}>
        <MainBlock staticTexts={staticTexts} home={home} requestLoading={requestLoading}/>
        {
            home?.blocks ? <HomeBlock staticTexts={staticTexts} block={home?.blocks[0]}/> : ''
        }
        <HomePartners partners={partners} staticTexts={staticTexts}/>
        <HomeServices staticTexts={staticTexts} services={services}/>
        {
            home?.blocks ?  <Contracts partners={partners} staticTexts={staticTexts}  block={home?.blocks[1]}/> : ''
        }
        {
            home?.blocks ? <MapBlock staticTexts={staticTexts} block={home?.blocks[2]}/> : ''
        }
        {
            home?.blocks ? <HomePackage staticTexts={staticTexts} block={home?.blocks[3]}/> : ''
        }
        {
            home?.blocks ? <HomeBlock staticTexts={staticTexts} block={home?.blocks[4]} reverse={true}/> : ''
        }
        {
            home?.blocks ? <Subscribers staticTexts={staticTexts} block={home?.blocks[5]}/> : ''
        }
        {/*<HomeNews info={lastNews} staticTexts={staticTexts}/>*/}
        {
            home?.blocks ? <HomeContact contacts={contacts} staticTexts={staticTexts} block={home?.blocks[6]}/> : ''
        }
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'partners',
        'requestLoading',
        'staticTexts',
        'contacts',
        'projects',
        'sponsors',
        'home',
        'about',
        'services',
        'blogs'
    ])
};

const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
