// Import packages
import React, {memo} from "react";
import {connect} from 'react-redux';
import {Link, NavLink} from "react-router-dom";

// Import assets
import './mobileMenu.scss';

// Import components
import {getPropsFromState} from "../../redux/mapStateToProps";
import {ChangeLanguage} from "../../redux/actions";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";
import {history} from "../../configs/history";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {CloseMenuIcon} from "../../assets/images";

// Import utils



const MobileMenu = memo((props) => {
    const {className, staticTexts, toggleMobileMenu,languages,selectedLanguage} = props;


    return <section className={`right-menu-wrapper ${className}`}>
        <div className={`right-menu-content ${className}`}>
            <div className={'close-menu-block'} onClick={toggleMobileMenu}>
                <CloseMenuIcon/>
            </div>
            <nav className='mobile-nav'>
                <NavLink to={getUrlWithLocal('/about')} activeClassName='active'>
                    <div className="nav-item" onClick={toggleMobileMenu}>{staticTexts?.header_item_about}</div>
                </NavLink>
                <NavLink to={getUrlWithLocal('/services')} activeClassName='active'>
                    <div className="nav-item" onClick={toggleMobileMenu}>{staticTexts?.header_item_services}</div>
                </NavLink>
                <NavLink to={getUrlWithLocal('/info')} activeClassName='active'>
                    <div className="nav-item" onClick={toggleMobileMenu}>{staticTexts?.header_item_news}</div>
                </NavLink>
                <NavLink to={getUrlWithLocal('/contact')} activeClassName='active'>
                    <div className="nav-item" onClick={toggleMobileMenu}>{staticTexts?.header_item_contact}</div>
                </NavLink>
                <div className="language-items">
                    {
                        languages?.length && languages?.map((item) => {
                            return <div key={item.id}
                                        onClick={() => {
                                            history.push(`/${item.code}${history.location.pathname.slice(3)}`)
                                            props.ChangeLanguage(item)
                                        }}
                                        className={'language-dropdown-item'}>
                                <div className={`selected-language 
                                    ${selectedLanguage?.code === item.code ? 'active-language' : ''}`}>
                                    <img src={generateImageMediaUrl(item?.icon?.path)}/>
                                </div>
                            </div>
                        })
                    }
                </div>
            </nav>
        </div>
        <div className={`mobile-menu-overlay`} onClick={toggleMobileMenu}/>
    </section>
})

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'languages',
        'selectedLanguage',
        'services',
    ])
};
const mapDispatchToProps = {
    ChangeLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
