import React, {useEffect, useState} from "react";

//Import Assets
import "./NewsCard.scss";
import {generateImageMediaUrl} from "../../../../utils/generateMediaUrl";
import {ShareIcon} from "../../../../assets/images";
import moment from "moment";
import NewsModal from "../../modal/newsModal/NewsModal";

function NewsCard(props){
    const {item,selectedLanguage} = props
    const [modalOpen,setModalOpen] = useState(false)
    const [news,setNews] = useState()

    function toggleModalOpen(news){
        setNews(news)
        setModalOpen(!modalOpen)
    }
    function toggleModalClose(){
        setModalOpen(false)
    }

    // console.log(selectedLanguage,'selectedLanguageselectedLanguage')

    return <div className={'news-card'}>
        <div className={'news-card-img'} onClick={() => toggleModalOpen(item)}>
            <img src={generateImageMediaUrl(item?.mediaMain?.path)}/>
        </div>
        <div className={'news-card-info'} onClick={() => toggleModalOpen(item)}>
            <div className={'news-card-date'}>
                <span className={'date'}>{item?.date && moment(item?.date).format('ll')}</span>
                <span className={'time'}>{item?.time && moment(item?.time).format('HH:hh')}</span>
            </div>
            <div className={'news-card-title-wrapper'}>
                <div className={'news-card-title'}>
                    {item?.title}
                </div>
                <div className={'news-card-link'} onClick={() => toggleModalOpen()}>
                    <ShareIcon/>
                </div>
            </div>
            <div className={'news-card-description'} dangerouslySetInnerHTML={{__html: item?.description}}/>
        </div>
        {
            news && <NewsModal toggleModalOpen={toggleModalOpen} modalOpen={modalOpen} newsId={news?.id}/>
        }
    </div>
}
export default NewsCard