import {_urlNews, request} from "../api";
import {NEWS_CONST} from "../constants";

export const GetNews = ({
                            reset = true,
                            offset = 0,
                            category,
                            limit = 6,
                        } = {}) => {
    let url = `${_urlNews}?offset=${offset}&limit=${limit}`;
    category && (url += `&category=${category}`);
    const requestData = {
        url,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: NEWS_CONST.GET_NEWS,
                    payload: {
                        data,
                        reset,
                        hasMore: data.length === limit,
                    }
                })
            })
    };
};

export const GetNewsBlock = (id) => {
    const requestData = {
        url: `${_urlNews}/${id}`,
        method: "GET",
        languageFlag: true
    };

    return request(requestData) //
        .then((res) => res?.data)
        .catch((error) => {
            console.error("Error fetching news:", error);
            return null;
        });
};