import './news.scss'
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetNews} from "../../redux/actions";
import {connect} from "react-redux";
import {
    bg_decorative,
    PagLeftIcon, PagRightIcon,
} from "../../assets/images";
import {useEffect, useRef, useState} from "react";
import NewsCard from "../../components/uiElements/card/newsCard/NewsCard";
import React from "react";
import {PAGE_NEWS_COUNT} from "../../constants/constType";
import {Pagination} from "antd";

function News(props) {
    const {news, staticTexts,selectedLanguage} = props
    const [currentPage, setCurrentPage] = useState(1);
    const isFetching = useRef(false)


    useEffect(() => {
        props.GetNews()
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])

    async function onPageChanged(news) {
        let count = news - 1
        isFetching.current = true;
        const configInfo = {
            reset: true,
            offset: count * PAGE_NEWS_COUNT,
            limit: PAGE_NEWS_COUNT,
        }
        await props.GetNews(configInfo)
        setCurrentPage(news);
        window.scrollTo({
            top: 0
        });
    }

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a className={'prev_btn'}>
                <PagLeftIcon className={'arrow_icon'}/>
                {/*<span>*/}
                {/*    {staticTexts?.blog_page_prev}*/}
                {/*</span>*/}
            </a>;
        }
        if (type === 'next') {
            return <a className={'next_btn'}>
                {/*<span>*/}
                {/*    {staticTexts?.blog_page_next}*/}
                {/*</span>*/}
                <PagRightIcon className={'arrow_icon'}/>
            </a>;
        }
        return originalElement;
    };


    return <div className={'news-wrapper'}>
        <div className={'news-block'}>
            <img src={bg_decorative} className={'info-wrapper-bg'} alt=""/>
            <div className={'info-sub-title'}>{staticTexts?.news_subTitle}</div>
            <div className={'info-title'}>{staticTexts?.news_title}</div>
            <div className={'info-description'}>{staticTexts?.news_description}</div>

            <div className={'news-slider'}>
                {news?.itemsList?.length && news?.itemsList?.map(item => {
                    return <NewsCard item={item} selectedLanguage={selectedLanguage}/>
                })}

                <div className={'news_pagination'}>
                    <Pagination current={currentPage}
                                onChange={onPageChanged}
                                simple={false}
                                total={news?.count}
                                defaultPageSize={6}
                                hideOnSinglePage={true}
                                itemRender={itemRender}
                                className={`pagination`}
                    />
                </div>
            </div>
        </div>
    </div>

}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'news',
        'staticTexts',
        'selectedLanguage'
    ])
};

const mapDispatchToProps = {
    GetNews,
};


export default connect(mapStateToProps, mapDispatchToProps)(News);