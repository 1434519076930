// import packages
import React, {useEffect, useRef, useState} from 'react';

//Import Components

//Import assets
import "./NewsModal.scss"

// Import utils
import {connect} from "react-redux";
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {Modal} from "antd";
import {generateFileMediaUrl, generateImageMediaUrl} from "../../../../utils/generateMediaUrl";
import moment from "moment";
import {EllipseIcon, ModalCloseIcon} from "../../../../assets/images";
import Slider from "react-slick";
import {GetNewsBlock} from "../../../../redux/actions";


function NewsModal(props){
    const {modalOpen,toggleModalOpen,newsId,staticTexts} = props
    const [imgList ,setImgList] = useState([])
    let sliderRef = useRef(null);
    const [newsBlock, setNewsBlock] = useState(null);

    useEffect(() => {
        const fetchNews = async () => {
            if (!newsId) return;
            try {
                const data = await GetNewsBlock(newsId);
                setNewsBlock(data);
            } catch (error) {
                console.error("Fetching news failed", error);
            }
        };

        fetchNews();
    }, [newsId]);

    useEffect(() => {
        if (newsBlock) {
            const list = [
                newsBlock?.mediaMain?.path,
                ...(newsBlock?.medias?.map(item => item?.path) || [])
            ];
            setImgList(list);
        }
    }, [newsBlock]);

    function closeModal(){
        setImgList([]);
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(0);
        }
        toggleModalOpen();
    }


    const settings = {
        dots: true,
        fade: true,
        arrows: false,
        autoplay:false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        ref: sliderRef,
    };

    function navigateTo(link) {
        window.open(link)
    }

    return newsBlock ? <Modal centered
                  title={''}
                  open={modalOpen}
                  closeIcon={<ModalCloseIcon/>}
                  className={'newsModal'}
                  maskClosable={true}
                  zIndex={10001}
                  onCancel={closeModal}>
        <div className={'news-modal-wrapper'}>
            <Slider {...settings}>
                {imgList?.length && imgList.map((item,index) =>{
                    return <div className={'news-block-img'} key={index}>
                        <img src={generateImageMediaUrl(item)}/>
                    </div>
                })}
            </Slider>
            <div className={'news-block-info'}>
                <div className={'news-date'}>
                    {moment(newsBlock?.date).format('ll')}
                </div>
                {
                    newsBlock?.link && <a href={newsBlock?.link} target={'_blank'} className={'news-modal-link'} >
                        {staticTexts?.news_link}
                    </a>
                }
                <div className={'news-block-title'}>
                    {newsBlock?.title}
                </div>
                <div className={'news-block-description'} dangerouslySetInnerHTML={{__html: newsBlock?.description}}/>
                {
                    newsBlock?.presentationFile && <div onClick={()=>navigateTo(generateFileMediaUrl(newsBlock?.presentationFile?.path))} className={'news-modal-pdf'} >
                        {staticTexts?.news_pdf}
                    </div>
                }
            </div>
        </div>
    </Modal> : ''
}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'newsBlock',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    GetNewsBlock
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsModal)