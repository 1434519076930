import {initialState as general} from "../reducers/general";
import {initialState as utils} from "../reducers/utils";
import {initialState as service} from "../reducers/service";
import {initialState as contact} from "../reducers/contact"
import {initialState as about} from "../reducers/about"
import {initialState as news} from "../reducers/news"

const reducers = {
    general,
    utils,
    service,
    contact,
    about,
    news,
};

export default state => {
    const sData = {};
    Object.keys(reducers).forEach(selectorKey => {
        Object.keys(reducers[selectorKey]).forEach(key => {
            sData[key] = state[selectorKey][key];
        });
    });
    return sData;
};





