import { useEffect, useState } from "react";
import {connect} from "react-redux";
import moment from "moment";
import 'moment/locale/ru';
import 'moment/locale/hy-am';

//Assets
// import '../assets/styles/layout.scss'

//Components
import MobileMenu from "../components/mobileMenu/MobileMenu";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

//Hooks
import {getPropsFromState} from "../redux/mapStateToProps";
import {
    GetLanguages,
    GetStaticTexts,
    GetPartners, GetHome,
    GetServices,
    GetInfo,
    GetContacts, GetAbout,GetNews
} from "../redux/actions";


function Layout (props) {
    const { children,languages,selectedLanguage,isOpen} = props
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [changeLanguage, setChangeLanguage] = useState(false)

    function toggleMobileMenu () {
        setMobileMenuOpen(!mobileMenuOpen)
    }

    function toggleLanguage() {
        setChangeLanguage(!changeLanguage)
    }

    useEffect(() => {
        if (selectedLanguage?.code === 'hy') {
            moment.locale('hy-am');
        }else {
            moment.locale(selectedLanguage?.code);
        }
         props.GetStaticTexts().finally(() => {
            props.GetLanguages();
        });
        props.GetPartners()
        props.GetHome()
        props.GetServices()
        props.GetInfo()
        props.GetContacts()
        props.GetAbout()
        props.GetNews()
    },[selectedLanguage?.id])
    return <div className={`layout-wrapper ${isOpen ? 'fixed' : ''}`}>
        <Header mobileMenuOpen={mobileMenuOpen}
                changeLanguage={changeLanguage}
                toggleLanguage={toggleLanguage}
                toggleMobileMenu={toggleMobileMenu}/>
        <MobileMenu className={mobileMenuOpen ? 'expand_on' : ''}
                    mobileMenuOpen={mobileMenuOpen}
                    toggleMobileMenu={toggleMobileMenu}/>
        <div>
            {children}
        </div>
        <Footer mobileMenuOpen={mobileMenuOpen}
                toggleMobileMenu={toggleMobileMenu}/>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'languages',
        'selectedLanguage',
        'categories',
        'staticTexts',
        'menuList',
        'blogs',
        'isOpen'
    ])
};

const mapDispatchToProps = {
    GetLanguages,
    GetStaticTexts,
    GetPartners,
    GetHome,
    GetServices,
    GetInfo,
    GetContacts,
    GetAbout,
    GetNews,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
